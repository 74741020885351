<script setup lang="ts">
  const config = useState<{title:string, icon:string, rntPage:string}>("pageconfig")
</script>
<template>
    <div>
        <Header></Header>
        <div class="h-max bg-slate-500 pt-2">
            <PageHeader icon="folder" :title="config.title">
                <div class="flex space-x-2">

                   

                </div>
            </PageHeader>


            <div class="     border border-slate-400 p-6  ">

                <div class="bg-slate-300 rounded p-6  ">
                    <div class="flex justify-between mb-2 px-2">
                        
                    </div>
                    <slot />

                </div>

            </div>




        </div>
        <Footer></Footer>
    </div>
</template>
<style lang="scss">
body {
    background-color: #cdcdcf;
}
</style>